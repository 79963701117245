.table {
  @if $enable-rounded {
    border-radius: var(--bs-border-radius);
  }
  --bs-table-bg: transparent;
  .btn {
    margin-bottom: $gung-button-in-table-margin-bottom;
  }
}

.table-sm {
  padding: $table-cell-padding-sm;
}

.fit-column {
  width: 1%;
  white-space: nowrap;
  text-align: right;
}
.gung-responsive {
  /*Mobile View*/
  @media only screen and (max-width: 760px) {
    &.table-responsive {
      display: table;
    }
    &.table-responsive-sm {
      display: table;
    }
    .gung-r-display-n{
        display: none;
    }

    td,
    tr,
    th {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tfoot tr{
        border-top: 2px solid #000;
    }
    

    tr + tr {
      margin-top: 1em;
    }

    td, tfoot th {
      /* make like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 52%;
      text-align: left;
     
    }

    td:before, tfoot th:before {
      content: attr(data-label);
      display: inline-block;
      line-height: 1.5;
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
    }

    .buy-btn-width-150 {
      width: auto !important;
    }
  }
}

// add class to thead (and if necessary the component add display block)
.thead-sticky {
  position: sticky;
  z-index: 3;
  top: 110px;
  background: white;
}