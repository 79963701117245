/* @font-face {
  font-family: 'Futura';
  font-weight: bold;
  src: url('/assets/fonts/Futura-Bold-03.ttf');
}
@font-face {
  font-family: 'Futura';
  font-weight: normal;
  src: url('/assets/fonts/Futura-Medium-01.ttf');
}
@font-face {
  font-family: 'Futura';
  font-weight: normal;
  font-style: italic;
  src: url('/assets/fonts/Futura-MediumItalic-02.ttf');
} */

@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100..900&display=swap');

$font-family-base: "Inter Tight", sans-serif;
$font-family-sans-serif: "Inter Tight", sans-serif;

$primary: #2d495f;
$secondary: #2d495f;
$custom-colors: (
  'sjoborg-orange': #dd9933,
  'menu-links': #2d495f,
);

$gung-logo: false;
//$gung-login-background: '/assets/login-bg.jpg';

$body-bg: #f2f2f2;
$enable-rounded: true;
// $gung-login-background: "https://cdn2.gung.io/sjoborgs/Sjöborgs Hero.mov";