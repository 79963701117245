$theme-colors: map-merge($theme-colors, $custom-colors);

.fixed-search {
  position: sticky !important;
  // top: 80px!important;
  margin-bottom: 0px !important;
  top: 120px !important;
  padding-top: 0;
  margin-top: 0;
  margin-left: 0;
  max-width: 100%;
  padding: 0;
}

.gung-stepper {
  top: 80px !important;
  max-width: 100%;
  padding-top: 5px;
  .stepper-control-top {
    width: 100%;
    margin-left: unset;
  }
}

.margin-top-when-fixed-top-filters {
  margin-top: none !important;
}

lib-buy-btn {
  width: 100px;
}

sjoborg-buy-btn {
  width: 100px;
}

.conceptList .margin-top-when-fixed-top-filters {
  margin-top: 0 !important;
}

.circle {
  margin-right: 5px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  &.a {
    background-color: gray;
  }
  &.b {
    background-color: red;
  }
}

.login-container img {
  max-width: 100%;
}

nav.navbar .navbar-top-info a.small {
  color: white !important;
}

.navbar-brand img {
  @media (min-width: 451px) and (max-width: 500px) {
    width: 150px !important;
    height: auto !important;
  }

  @media (min-width: 401px) and (max-width: 450px) {
    width: 100px !important;
    height: auto !important;
  }

  @media (max-width: 400px) {
    width: 70px !important;
    height: auto !important;
  }
}

ul.main-menu a {
  color: #7f7f7f;
}

.gung-mega-menu {
  h6 {
    margin: 0;
  }
  & ul li {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.6rem;
    & span {
      font-size: 0.8rem;
      line-height: 1.6rem;
    }
  }
}

.card-assortment-tree .card-body {
  background-color: #fff;
  border: 2px solid #2d495f;
  border-radius: 0.5rem;
  // background-color: map-get($theme-colors, primary);
  .card-title a {
    color: #2d495f;
  }
}

.card-assortment-tree .card-body:hover {
  background-color: #2d495f;
  transition: 0.3s;
  .card-title a {
    color: #fff;
  }
}

.card-assortment-tree .card-title {
  margin-bottom: 0px;
}

.card-assortment-tree.card-border-top {
  border-top: none;
}

.product-link {
  color: map-get($theme-colors, primary);
}

.margin-top-when-fixed-top-filters {
  margin-top: 0.75rem !important;
}

.cart-menu-wrapper {
  .cart-button {
    .badge {
      position: absolute;
      top: -4px;
      font-weight: normal;
      font-size: 8px;
      right: -5px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid #000;
      color: black;
    }
  }
}

lib-saved-cart-list .accordion-header .cart-header .btn-link {
  color: $primary;
}

.badge.badge-primary.cart-icon-badge.rounded-circle {
  position: absolute;
  top: -4px;
  font-weight: normal;
  font-size: 8px;
  right: -5px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #000;
  color: black;
}

sjoborg-navbar .fav-menu .badge{
  color:#000;
}

.expand-menu {
  background-color: white !important;
  .second-menu .secondary-menu {
    width: 20% !important;
  }
}

@media (max-width: 768px) {
  .expand-menu .second-menu .secondary-menu {
    width: 40% !important;
  }
}

@media (max-width: 576px) {
  .expand-menu .second-menu .secondary-menu {
    width: 100% !important;
  }
}

lib-fashion-login .login-container,
.login-gung-theme .bg-container .login-container,
.login-gung-theme .container.fashion-login .login-container {
  background-color: #fff;

  .btn.btn-primary {
    background-color: #2d495f;
  }

  a.forgot-password-text {
    color: #2d495f;
  }

  input {
    color: #2d495f;
  }

  lib-language-selection-dropdown button.dropdown-toggle.btn {
    border-radius: unset;
  }
}